

import { inject } from '@/inversify';
import { Component, Mixins } from 'vue-property-decorator';
import RatesGraphCompset from '@/modules/rates/components/graph/rates-graph-compset.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import RatesGraphTypeChanger from '@/modules/rates/components/graph/rates-graph-type-changer.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import type Day from '@/modules/common/types/day.type';
import OccupancyDemandGraph from '@/modules/common/components/ui-kit/occupancy-demand-graph/index.vue';
import RatesPageOtelLogs from '@/modules/open-telemetry/mixins/rates/rates-page-otel-logs.mixin';
import RatesOutOfRangeMessage from '../components/rates-out-of-range-message.vue';

@Component({
    components: {
        PageWrapper,
        RatesHeader,
        TogglePrices,
        RatesActions,
        OccupancyDemandGraph,
        RatesGraphCompset,
        DateDocumentFilter,
        RatesGraphTypeChanger,
        RatesOutOfRangeMessage,
    },
})
export default class RatesGraphСompsetPage extends Mixins(RatesPageOtelLogs) {
    @inject(UserServiceS) private userService!: UserService;

    day: Day | null = null;

    async updateDay(day: Day | null) {
        this.day = day;
    }

    get demandGraphOffset() {
        const { maxPrices } = this.ratesService.minMaxPrices(this.userService.currentHotelId);
        const filteredPrices = maxPrices.filter(price => price !== null) as number[];
        const maxPrice = Math.max(...(filteredPrices.length ? filteredPrices : [0]));
        const symbolWidth = 7.5;
        return maxPrice ? String(maxPrice).length * symbolWidth : 0;
    }

    get showFooter() {
        return !this.isNonSupportedModal && !this.isOutOfRange;
    }

    get isOutOfRange() {
        return this.ratesService.isOutOfRange() && !this.ratesService.isLoading;
    }

    get isNonSupportedModal() {
        return this.$route.name!.includes('.not-supported');
    }

    get demandAndOccupancy() {
        if (!this.documentFiltersService.days) {
            return null;
        }
        const demandAndOccupancyArray: {demand: (number | null), occupancy: (number | null)}[] = [];
        this.documentFiltersService.days.forEach(day => {
            const demandValue = this.ratesService.getDemand(day);
            const occupancyValue = this.ratesService.getOccupancy(day);
            demandAndOccupancyArray.push({ demand: demandValue, occupancy: occupancyValue });
        });
        return demandAndOccupancyArray;
    }
}
